import React from 'react';

import Exhibitions from '../components/exhibitions';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ExhibitionsPage = () => {
  return (
    <Layout>
      <SEO
        title="Exhibitions"
        description="List of exhibitions of the artist Katharina Welper."
      />
      <div className="page">
        <div className="about-section">
          <Exhibitions />
        </div>
      </div>
    </Layout>
  );
};

export default ExhibitionsPage;
