import React from 'react';
import PropTypes from 'prop-types';

const Exhibitions = ({ locale = 'en' }) => (
  <div className="about" id="about">
    <h2>{locale === 'pt-BR' ? 'Exposições' : 'Exhibitions'}</h2>

    <ul className="exhibitions">
      <li>
        <time>2021</time> &mdash; Mere at det same - Kultuhuset - Skanderborg,
        Danmark - Individual
      </li>
      <li>
        <time>2021</time> &mdash; KP-20, Kunsthal - Århus , Denmark - collective
      </li>
      <li>
        <time>2019</time> &mdash; Exhibition in 1st Dibs/Equinocial Gallery,
        Chelsea, New York 2018 -Brazilian DesignArt Auction, Piasa Gallery,
        Paris &mdash; collective 2018 -SESC -Palácio Quitandinha, Petrópolis
        &mdash; collective
      </li>
      <li>
        <time>2017</time> &mdash; SP Arte, São Paulo
      </li>
      <li>
        <time>2017</time> &mdash; Brazilian DesignArt Auction, Piasa Gallery,
        Paris &mdash; collective 2017 &mdash; Arte para Todos -Galeria
        Carpintaria &mdash; Jockey Club &mdash; Rio de Janeiro -collective
      </li>
      <li>
        <time>2016</time> &mdash; IDA &mdash; Arte Rio, Rio de Janeiro
        &mdash;2016 &mdash; Olhar feminino, SESC - Palácio Quitandinha,
        Petrópolis &mdash; collective
      </li>
      <li>
        <time>2016</time> &mdash; Brazilian DesignArt Auction Piasa Gallery,
        Paris &mdash; collective
      </li>
      <li>
        <time>2016</time> &mdash; Brazil S/A &mdash; São Paulo &mdash; a
        selection of pieces to represent Brazil at Milano Salone del mobile -
        collective
      </li>
      <li>
        <time>2015</time> &mdash; MADE, Jockey Club de São Paulo, São Paulo
        &mdash; collective 2014-Berlin am Meer Gallery, Berlin &mdash;
        collective
      </li>
      <li>
        <time>2014</time> &mdash; Brasil Embassy in Berlin, Berlin, Germany
        &mdash; collective 2013-Diálogo, Ava Gallery, Helsinki,
        Finlandia-collective
      </li>
      <li>
        <time>2013</time> &mdash; “Isto é uma mesa” &mdash; Museu Histórico
        Nacional, Rio de Janeiro &mdash; collective
      </li>
      <li>
        <time>2012</time> &mdash; Lugar comum &mdash; SESC -Palácio Quitandinha,
        Petrópolis &mdash; collective
      </li>
      <li>
        <time>2012</time> &mdash; Linhas Moiras, galeria TNT, Rio de janeiro
        &mdash; individual
      </li>
      <li>
        <time>2011</time> &mdash; Bonhams Gallery auction, Los Angeles &mdash;
        collective
      </li>
      <li>
        <time>2009</time> &mdash; Believe It &mdash; galeria TNT, Rio de Janeiro
        &mdash; collective
      </li>
      <li>
        <time>2007</time> &mdash; Katavento &mdash; Câmara dos Deputados,
        Brasilia &mdash; individual
      </li>
      <li>
        <time>2007</time> &mdash; Seleções da Arte Contemporânea, Centro
        Cultural dos Correios, Rio de Janeiro &mdash; collective
      </li>
      <li>
        <time>2006</time> &mdash; Galeria Haus, Rio de Janeiro -individual
      </li>
    </ul>

    <p>
      <em>
        Work as a visual artist since 2005, participating in exhibitions in
        Brazil
      </em>
    </p>

    <p>
      Her work unfolded for design in 2014, when she was invited to design 5
      tables for the exhibition "this is a table" at the Rio de Janeiro National
      Historical Museum, among some of the most important Brazilian designers.
      Her design pieces are the result of her artwork and experience building a
      boat.
      <br />
      <br />
      2014 exposed the table "Oito" on MADE, Mercado de Arte e Design, no Jockey
      Club, São Paulo
      <br />
      2015, exposed the table "Muxarabi" on Brazil S/A, uma seleção de peças
      para o Salão de Milão 2015
      <br />
      2016, our Table Basse was sold at Piasa Gallery auction in Paris 2016,
      exposed the table "Poça" on Arte Rio, no Rio de Janeiro.
      <br />
      2017, our table "poça 1, 2" was sold at Piasa Gallery auction in Paris
    </p>
  </div>
);

Exhibitions.propTypes = {
  locale: PropTypes.string
};

export default Exhibitions;
